import axios from '@/axios'
//const route = 'budget/cost'
export default{
    getAll(ItemsPerPage,page,search){
        let route = 'helper/get_all_cost'
        return  axios.get(route+'?page='+page+'&items_per_page='+ItemsPerPage+ '&search=' + search)
    },

    getStages(budget_id) {
         const route = 'report/stage/get_services'
         return axios.get(route + "/" + budget_id)
    },

    exportExcel(stage_id) {
        const route = 'report/stage'
        return axios.get(route + '/export_excel_compositions/'+stage_id,{responseType: 'blob'})
    },

    exportStageExcel(cost_id) {
        const route = `report/stage/export_stage/excel/${cost_id}`
        return axios.get(route ,{responseType: 'blob'})
    },

    getCompositions(stage_id){
        const route = 'report/stage/get_compositions/'
        return axios.get(route + stage_id)
    },


}
